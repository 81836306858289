import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataValue } from '@app/models/employee/data-value.model';
import { DataFieldGroup } from '@app/models/settings/data-field-group.model';
import { DataField } from '@app/models/settings/data-field.model';
import { AuthService } from '@app/services';

@Injectable()
export class DataFieldsService {
    constructor(
        protected auth: AuthService,
        private http: HttpClient
    ) {}

    async getEmployeeDataFields(dataFieldGroupComponent = ''): Promise<DataField[]> {
        // Want to get the datafields from the company endpoint and not an include so that they are filtered by permissible
        const [dataFields] = await DataField.param('company', this.auth.company.id)
            .with(['dataFieldGroup', 'dataFieldOptions'])
            .where('employeeIds', [this.auth.employee.id])
            .when(dataFieldGroupComponent, (query) => query.where('dataFieldGroupComponent', dataFieldGroupComponent))
            .all();

        return dataFields;
    }

    async getEditableEmployeeDataFields(dataFieldGroupComponent = ''): Promise<DataField[]> {
        return (await this.getEmployeeDataFields(dataFieldGroupComponent)).filter((dataField) => dataField.editable);
    }

    async getEmployeeDataValues(): Promise<DataValue[]> {
        const [dataValues] = await DataValue.param('company', this.auth.company.id)
            .where('employee_id', this.auth.employee.id)
            .all();

        return dataValues;
    }

    async getEditableEmployeeDataValues(): Promise<DataValue[]> {
        return (await this.getEmployeeDataValues()).filter((dataValue) => dataValue.canEdit);
    }

    // Retrieve unique data field groups from an array of data fields
    getUniqueGroupsFromDataFields(dataFields: DataField[]): DataFieldGroup[] {
        const groupIds = [];

        const groups: DataFieldGroup[] = [];
        for (const field of dataFields) {
            if (!groupIds.includes(field.dataFieldGroup.id)) {
                groupIds.push(field.dataFieldGroup.id);
                groups.push(field.dataFieldGroup);
            }
        }

        return groups;
    }

    getDataFieldsByGroup(id: number, dataFields: DataField[]): DataField[] {
        return dataFields.filter((dataField) => dataField.dataFieldGroup?.id === id && dataField.editable);
    }

    async notifyAdminToFillDataFields(): Promise<void> {
        const url = `/v2/companyManagement/companies/${this.auth.employee.companyId}/notifications/dataFields/notify/${this.auth.employee.id}`;

        await this.http.post(url, null, { observe: 'response' }).toPromise();
    }
}
