import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Feedback } from '@app/models/performance/feedback.model';

export class VisibilityColumn extends Column {
    title = 'Visibility';
    property = 'visibility';
    sortField = 'visibility';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(feedback: Feedback): string {
        const visibility = feedback.visibility;
        const titlecasedVisibility = visibility.charAt(0).toUpperCase() + visibility.slice(1);
        return titlecasedVisibility;
    }
}
