import { OffboardingReminder } from '@app/models/employee/offboarding-reminder.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Translatable } from '@app/types/translatable.type';

export class DelayDaysColumn extends Column {
    title = 'table.employeeSettingsOffboarding.sendAt';
    property = 'delayDays';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(object: OffboardingReminder): Translatable {
        if (!object.delayDays) {
            return 'table.employeeSettingsOffboarding.onLastDay';
        }

        if (object.remindBefore) {
            return {
                key: 'table.employeeSettingsOffboarding.beforeDelayDays',
                params: {
                    delayDays: object.delayDays,
                },
            };
        }

        return {
            key: 'table.employeeSettingsOffboarding.afterDelayDays',
            params: {
                delayDays: object.delayDays,
            },
        };
    }
}
