import { Department } from '@app/models/company/department.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class TitleColumn extends Column {
    title = 'table.departments.name';
    property = 'name';
    sortable = true;
    type: CellDisplay = CellDisplay.colorCircle;
    displayWidth = 60;

    getRowStyling(department: Department): { [key: string]: string } {
        return { borderColor: department.color };
    }
}
