import { Injectable } from '@angular/core';
import { Paginator } from '@app/classes';
import { Task } from '@app/models/tasks/task.model';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import {
    AssignedToColumn,
    DueColumn,
    NameColumn,
    RequiredForColumn,
    SubTasksCountColumn,
    TaskStatusColumn,
} from './columns';

const remindOption: MenuColumnItem = {
    label: 'table.all-company-tasks.remind',
    event: 'remind',
};

const viewTemplateOption: MenuColumnItem = {
    label: 'table.all-company-tasks.viewTemplate',
    event: 'viewTemplate',
};

const editOption: MenuColumnItem = {
    label: 'table.all-company-tasks.edit',
    event: 'edit',
};

const deleteOption: MenuColumnItem = {
    label: 'table.all-company-tasks.delete',
    event: 'delete',
};

@Injectable()
export class AllCompanyTasksTable extends Table {
    links = false;
    showMenuColumnOptions = true;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    getMenuColumnOptionsForRow(task: Task): MenuColumnItem[] {
        if (this.auth.can(Task.managePermission)) {
            if (task.taskTemplateId && task.completedAt) {
                return [viewTemplateOption, editOption, deleteOption];
            }

            if (task.taskTemplateId && !task.completedAt) {
                return [remindOption, viewTemplateOption, editOption, deleteOption];
            }

            return [remindOption, editOption, deleteOption];
        }
        return [];
    }

    setColumns(): void {
        this.columns = [
            new NameColumn(),
            new AssignedToColumn(),
            new RequiredForColumn(),
            new SubTasksCountColumn(),
            new DueColumn(),
            new TaskStatusColumn(),
        ];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            Task.param('company', this.auth.company.id).with([
                'subject',
                'subject.manager',
                'assignees',
                'assignees.manager',
                'subtasks',
                'file',
                'employeeDocument',
                'completedBy',
                'subtasksCount',
            ])
        );
    }
}
