import { AuthService } from '@app/services';
import { TrainingProgramRecord } from '@app/models/training/training-program-record.model';
import {
    CompletedDateColumn,
    ExpirationColumn,
    TrainingProgramColumn,
} from '@app/modules/training/services/tables/completed-records-index/columns';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class CompletedRecordsIndexTable extends Table {
    links = true;

    constructor(
        protected auth: AuthService,
        public route: ActivatedRoute
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        this.columns = [new TrainingProgramColumn(), new CompletedDateColumn(), new ExpirationColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            TrainingProgramRecord.param('company', this.auth.company.id)
                .with(['employee', 'trainingProgram'])
                .where('status', ['complete'])
                .where('employeeId', this.auth.employee.id)
        );
    }

    getLink(record: TrainingProgramRecord): (string | number)[] | null {
        return record.getLinkPath();
    }
}
