import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmployeeRehireDetails } from '@app/modules/employees/interfaces/employee-rehire-details.interface';
import { Employee } from '@models/employee/employee.model';
import moment from 'moment';
import { stringify } from 'qs/dist/qs';

@Injectable()
export class RehireService {
    constructor(private http: HttpClient) {}

    async rehire(
        employee: Employee,
        nextDayOfWorkOn: Date,
        rehireDetails: EmployeeRehireDetails
    ): Promise<HttpResponse<Object>> {
        const parameters = stringify({
            nextDayOfWorkOn: moment(nextDayOfWorkOn).format('YYYY-MM-DD'),
            reEnrollOnBenefits: rehireDetails.reEnrollOnBenefits,
        });
        const url = `/v2/companyManagement/employees/${employee.id}/rehire?${parameters}`;
        return await this.http.post(url, null, { observe: 'response' }).toPromise();
    }

    async revokePendingRehire(employee: Employee, nextDayOfWorkOn: Date): Promise<HttpResponse<Object>> {
        const parameters = stringify({
            nextDayOfWorkOn: moment(nextDayOfWorkOn).format('YYYY-MM-DD'),
        });
        const url = `/v2/companyManagement/employees/${employee.id}/rehire?${parameters}`;
        return await this.http.delete(url, { observe: 'response' }).toPromise();
    }
}
