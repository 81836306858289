import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class TaskColumn extends Column {
    title = 'Task';
    property = 'name';
    sortField = 'name';
    type: CellDisplay = CellDisplay.string;
    sortable = true;
}
