import { Goal } from '@app/models/performance/goal.model';
import { BadgeColumn } from '@app/modules/table/classes/badge-column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';

export class StatusColumn extends BadgeColumn<Goal> {
    title = 'Status';
    property = 'status';
    sortField = 'status.id';
    sortable = true;
    type: CellDisplay = CellDisplay.badge;

    getBadgeDisplay(goal: Goal): BadgeDisplay | undefined {
        return {
            type: goal.statusBadgeType,
            label: goal.status.toUpperCase(),
        };
    }
}
