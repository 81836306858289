import { TimeOffService } from '@app/modules/time-off-v3/services';
import { EmployeeChangeRequestTemplatesService } from '@employees/services/employee-change-request-templates.service';
import { TaskTemplatesTable } from '@employees/services/tables/task-templates/task-templates.table';
import { AllCompanyTasksTable } from '@employees/services/tables/tasks/company-tasks/all-company-tasks.table';
import { TaskTemplateCompanyTasksTable } from '@employees/services/tables/tasks/company-tasks/task-template-company-tasks.table';
import { UpcomingTrainingIndexTable } from '@employees/services/tables/training/upcoming-training-index.table';
import { ProjectEmployeeAssignmentService } from '@time-tracking/services';
import { FormFileManagerService } from '@training/services';
import { AssignTaskTemplatesService } from './assign-task-templates.service';
import { CompensationService } from './compensation.service';
import { DataFieldsService } from './data-fields.service';
import { EmployeeHiringService } from './employee-hiring.service';
import { EmployeeMenuService } from './employee-menu.service';
import { EmployeeModuleMenuService } from './employee-module-menu.service';
import { EmployeeRoeService } from './employee-roe.service';
import { RehireService } from './rehire.service';
import { DepartmentsTable } from './tables/departments/departments.table';
import { EmployeeDocumentsTable } from './tables/documents/employee-documents.table';
import { EmployeeAssetsTable } from './tables/employee-assets/employee-assets.table';
import { MyTeamTable } from './tables/employee-table/my-team.table';
import { EmploymentRecordsTable } from './tables/employment-records/employment-records.table';
import { EmployeeFeedbackTable } from './tables/feedback/employee-feedback.table';
import { EmployeeGoalsTable } from './tables/goals/employee-goals.table';
import { NewHireQuestionsTable } from './tables/new-hire-questions/new-hire-questions.table';
import { OffboardingReminderTable } from './tables/offboarding-reminder/offboarding-reminder.table';
import { OnboardingReminderTable } from './tables/onboarding-reminder/onboarding-reminder.table';
import { EmployeeReviewsTable } from './tables/reviews/employee-reviews.table';
import { OffboardingTaskTemplateTable } from './tables/task-template-table/offboarding-task-template.table';
import { OnboardingTaskTemplateTable } from './tables/task-template-table/onboarding-task-template.table';
import { TimesheetsEmployeeIndexTable } from './tables/time-tracking/time-tracking-employee-index.table';
import { TerminationService } from './termination.service';

const Services = [
    EmployeeHiringService,
    EmployeeRoeService,
    DataFieldsService,
    MyTeamTable,
    OnboardingTaskTemplateTable,
    DepartmentsTable,
    OnboardingReminderTable,
    EmploymentRecordsTable,
    AssignTaskTemplatesService,
    TimeOffService,
    CompensationService,
    UpcomingTrainingIndexTable,
    EmployeeReviewsTable,
    EmployeeFeedbackTable,
    EmployeeGoalsTable,
    NewHireQuestionsTable,
    OffboardingReminderTable,
    FormFileManagerService,
    OffboardingTaskTemplateTable,
    EmployeeAssetsTable,
    RehireService,
    TerminationService,
    TaskTemplatesTable,
    AllCompanyTasksTable,
    TaskTemplateCompanyTasksTable,
    EmployeeChangeRequestTemplatesService,
    ProjectEmployeeAssignmentService,
    EmployeeModuleMenuService,
    EmployeeMenuService,
];

export {
    AllCompanyTasksTable,
    AssignTaskTemplatesService,
    CompensationService,
    DataFieldsService,
    DepartmentsTable,
    EmployeeAssetsTable,
    EmployeeChangeRequestTemplatesService,
    EmployeeDocumentsTable,
    EmployeeFeedbackTable,
    EmployeeGoalsTable,
    EmployeeHiringService,
    EmployeeMenuService,
    EmployeeModuleMenuService,
    EmployeeReviewsTable,
    EmployeeRoeService,
    EmploymentRecordsTable,
    FormFileManagerService,
    MyTeamTable,
    NewHireQuestionsTable,
    OffboardingReminderTable,
    OffboardingTaskTemplateTable,
    OnboardingReminderTable,
    OnboardingTaskTemplateTable,
    ProjectEmployeeAssignmentService,
    RehireService,
    Services,
    TaskTemplateCompanyTasksTable,
    TaskTemplatesTable,
    TerminationService,
    TimesheetsEmployeeIndexTable,
    UpcomingTrainingIndexTable,
};
