import { Injectable } from '@angular/core';
import { AuthService } from '@app/services';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { Employee } from '@app/models/employee/employee.model';
import {
    TitleColumn,
    DateSentColumn,
    VisibilityColumn,
    SentimentTypeColumn,
    GivenByColumn,
    ReceivedByColumn,
} from './columns';
import { Feedback } from '@app/models/performance/feedback.model';

@Injectable()
export class EmployeeFeedbackTable extends Table {
    links = true;

    constructor(
        protected auth: AuthService,
        private employee: Employee
    ) {
        super(auth);
        this.boot();
    }

    getLink(feedback: Feedback): (string | number)[] {
        return ['/performance', 'feedback', feedback.id];
    }

    setColumns(): void {
        this.columns = [
            new TitleColumn(),
            new GivenByColumn(),
            new ReceivedByColumn(),
            new DateSentColumn(),
            new VisibilityColumn(),
            new SentimentTypeColumn(),
        ];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            Feedback.param('company', this.auth.company.id)
                .where('employeeId', this.employee.id)
                .with(['creator', 'subject'])
                .limit(5),
            { pageSize: 5 }
        );
    }
}
