import { TrainingProgram } from '@app/models/training/training-program.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Translatable } from '@app/types/translatable.type';
import {
    TrainingProgramDueDateManyDisplayOptions,
    TrainingProgramDueDateSingleDisplayOptions,
} from '@app/types/translatables/training-display.options';

export class DueDateColumn extends Column {
    title = 'training.dueDateAfterEnrollment';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(trainingProgram: TrainingProgram): Translatable {
        const { dueType, dueOffset } = trainingProgram;

        if (!dueType || !dueOffset) {
            return '—';
        }

        if (dueOffset <= 1) {
            return TrainingProgramDueDateSingleDisplayOptions[dueType] ?? `1 ${dueType}`;
        }

        const translationKey = TrainingProgramDueDateManyDisplayOptions[dueType] ?? null;
        if (!translationKey) {
            return `${dueOffset} ${dueType}s`;
        }

        return {
            key: translationKey,
            params: {
                periodQuantity: dueOffset,
            },
        };
    }
}
