import { Injectable } from '@angular/core';
import { Paginator } from '@app/classes';
import { Department } from '@app/models/company/department.model';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService } from '@app/services';
import { DateCreatedColumn, EmployeeCountColumn, TitleColumn } from '@employees/services/tables/departments/columns';

@Injectable()
export class DepartmentsTable extends Table {
    links = true;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        this.columns = [new TitleColumn(), new EmployeeCountColumn(), new DateCreatedColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(Department.param('company', this.auth.company?.id));
    }
}
