import { AuthService } from '@app/services';
import { TrainingProgram } from '@app/models/training/training-program.model';
import { NameColumn } from './columns';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { Injectable } from '@angular/core';
import { Employee } from '@models/employee/employee.model';

@Injectable()
export class EnrolledEmployeesTable extends Table {
    links = false;

    constructor(
        protected auth: AuthService,
        public program: TrainingProgram
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        this.columns = [new NameColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            Employee.param('company', this.auth.company.id).where('trainingProgramId', this.program.id)
        );
    }
}
