import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TerminationCode } from '@app/constants/termination-codes';
import { Employee } from '@models/employee/employee.model';
import moment from 'moment';
import { stringify } from 'qs/dist/qs';
import { EmployeeTerminationDetails } from '../interfaces/employee-termination-details.interface';

@Injectable({ providedIn: 'root' })
export class TerminationService {
    constructor(private http: HttpClient) {}

    async terminate(
        employee: Employee,
        lastDayOfWorkOn: Date,
        terminationReasonCode: TerminationCode,
        terminationComments: string,
        benefitsEndDate: Date | null = null
    ): Promise<HttpResponse<Object>> {
        const paramsToStringify: EmployeeTerminationDetails = {
            lastDayOfWorkOn: moment(lastDayOfWorkOn).format('YYYY-MM-DD'),
            terminationReasonCode: terminationReasonCode,
            terminationComments: terminationComments,
        };

        if (benefitsEndDate) {
            paramsToStringify.benefitsEndDate = moment(benefitsEndDate).format('YYYY-MM-DD');
        }

        const parameters = stringify(paramsToStringify);

        const url = `/v2/companyManagement/employees/${employee.id}/terminate?${parameters}`;
        return await this.http.post(url, null, { observe: 'response' }).toPromise();
    }

    async revokePendingTermination(employee: Employee, lastDayOfWorkOn: Date): Promise<HttpResponse<Object>> {
        const parameters = stringify({
            lastDayOfWorkOn: moment(lastDayOfWorkOn).format('YYYY-MM-DD'),
        });
        const url = `/v2/companyManagement/employees/${employee.id}/terminate?${parameters}`;
        return await this.http.delete(url, { observe: 'response' }).toPromise();
    }
}
