import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { TrainingProgramRecord } from '@app/models/training/training-program-record.model';
import { Employee } from '@app/models/employee/employee.model';

@Injectable()
export class TrainingProgramsService {
    constructor(private http: HttpClient) {}

    sendReminders(trainingProgramRecord: TrainingProgramRecord, selectedEmployees: Employee[]): Promise<any> {
        const url = this.getSendRemindersEndpoint(trainingProgramRecord);
        const payload = {
            employees: selectedEmployees.map((employee) => employee.id),
        };

        return this.http.post(url, payload).toPromise();
    }

    private getSendRemindersEndpoint(trainingProgramRecord: TrainingProgramRecord): string {
        return environment.api + `/v2/training/trainingProgramRecords/${trainingProgramRecord.id}/remind`;
    }
}
