import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Column } from '@app/modules/table/classes/column.class';
import { TrainingProgramRecord } from '@models/training/training-program-record.model';
import moment from 'moment';

export class DateColumn extends Column {
    title;
    property;
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    constructor(title: string, property: string) {
        super();
        this.title = title;
        this.property = property;
    }

    getDisplayPropertyValue(model: TrainingProgramRecord): string {
        const date = model.getAttribute(this.property);
        return date ? moment(date).format('MMM D, Y') : '-';
    }
}
