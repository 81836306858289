import { Injectable } from '@angular/core';
import { Paginator } from '@app/classes';
import { TaskTemplate } from '@app/models/tasks/task-template.model';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import {
    AssignedToColumn,
    NameColumn,
    RequiredForColumn,
    TasksCountColumn,
    TaskTypeColumn,
} from '@employees/services/tables/task-templates/columns';

const editOption: MenuColumnItem = {
    label: 'edit',
    event: 'edit',
};

const deleteOption: MenuColumnItem = {
    label: 'delete',
    event: 'delete',
};

@Injectable()
export class TaskTemplatesTable extends Table {
    links = true;
    showMenuColumnOptions = true;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    getMenuColumnOptionsForRow(): MenuColumnItem[] {
        if (this.auth.can(TaskTemplate.managePermission)) {
            return [editOption, deleteOption];
        }
    }

    setColumns(): void {
        this.columns = [
            new NameColumn(),
            new AssignedToColumn(),
            new RequiredForColumn(),
            new TaskTypeColumn(),
            new TasksCountColumn(),
        ];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            TaskTemplate.param('company', this.auth.company.id).with(['assignees', 'tasksCount'])
        );
    }
}
