import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class TitleColumn extends Column {
    title = 'Title';
    property = 'title';
    sortField = 'title';
    sortable = true;
    type: CellDisplay = CellDisplay.string;
}
