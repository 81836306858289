import { Injectable } from '@angular/core';
import { AuthService } from '@app/services';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { Employee } from '@app/models/employee/employee.model';
import { TitleColumn, StatusColumn } from './columns';
import { Goal } from '@app/models/performance/goal.model';

@Injectable()
export class EmployeeGoalsTable extends Table {
    links = true;

    constructor(
        protected auth: AuthService,
        private employee: Employee
    ) {
        super(auth);
        this.boot();
    }

    getLink(goal: Goal): (string | number)[] {
        return ['/performance', 'goals', goal.id];
    }

    setColumns(): void {
        this.columns = [new TitleColumn(), new StatusColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            Goal.param('company', this.auth.company.id)
                .where('employeeId', this.employee.id)
                .with(['assignments', 'assignments.employee'])
                .limit(5),
            { pageSize: 5 }
        );
    }
}
