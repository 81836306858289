import { Model } from '@models/core/base.model';
import { Role } from '@models/account/role.model';
import { ChangeRequestTemplateField } from '@models/change-requests/change-request-template-field.model';
import { ApprovalWorkflow } from '@models/approvals/approval-workflow.model';

export class ChangeRequestTemplate extends Model {
    protected static _resource = 'changeRequest/changeRequestTemplates';
    protected static _version = 'v2';
    protected static _serializeAttributes = ['name', 'description', 'isActive', 'roleIds', 'notifySubject'];

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get description(): string {
        return this._attributes['description'];
    }

    set description(val: string) {
        this._attributes['description'] = val;
    }

    get requestCount(): number {
        return this._attributes['requestCount'];
    }

    get isActive(): boolean {
        return !!this._attributes['isActive'];
    }

    set isActive(val: boolean) {
        this._attributes['isActive'] = val;
    }

    get roles(): Role[] {
        return this.hasMany(Role, 'roles');
    }

    set roleIds(val: number[]) {
        this._attributes['roleIds'] = val;
    }

    get changeRequestTemplateFields(): ChangeRequestTemplateField[] {
        return this.hasMany(ChangeRequestTemplateField, 'fields');
    }

    set changeRequestTemplateFields(val: ChangeRequestTemplateField[]) {
        this.setMany('fields', val);
    }

    get notifySubject(): boolean {
        return !!this._attributes['notifySubject'];
    }

    set notifySubject(val: boolean) {
        this._attributes['notifySubject'] = val;
    }

    get approvalWorkflow(): ApprovalWorkflow {
        return this.hasOne(ApprovalWorkflow, 'approvalWorkflow');
    }

    set approvalWorkflow(workflow: ApprovalWorkflow) {
        this.setOne('approvalWorkflow', workflow);
    }
}
