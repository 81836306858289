import { Translatable } from '@app/types/translatable.type';

const TaskTemplateTypesDisplayOptions: { [key: string]: Translatable } = {
    onboarding: 'employees.taskTemplateTypes.onboarding',
    offboarding: 'employees.taskTemplateTypes.offboarding',
    general: 'employees.taskTemplateTypes.general',
};

const TaskTemplateAssigneeTypeDisplayOptions: { [key: string]: Translatable } = {
    manager: 'employees.taskTemplateAssigneeType.manager',
    unassigned: 'employees.taskTemplateAssigneeType.unassigned',
};

const TaskTemplateAssigneeTypeSubjectMapDisplayOptions: { [key: string]: Translatable } = {
    onboarding: 'employees.taskTemplateAssigneeTypeSubjectMap.onboarding-newHire',
    offboarding: 'employees.taskTemplateAssigneeTypeSubjectMap.offboarding-offboardingEmployee',
    general: 'employees.taskTemplateAssigneeTypeSubjectMap.general-employee',
};

export {
    TaskTemplateAssigneeTypeDisplayOptions,
    TaskTemplateAssigneeTypeSubjectMapDisplayOptions,
    TaskTemplateTypesDisplayOptions,
};
