import { OffboardingReminder } from '@app/models/employee/offboarding-reminder.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Translatable } from '@app/types/translatable.type';
import { RemindToTypesDisplayOptions } from '@app/types/translatables/remind-to-types-display.options';

export class SendToColumn extends Column {
    title = 'table.employeeSettingsOffboarding.sendTo';
    property = 'remindToName';
    sortField = 'remindTo';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(object: OffboardingReminder): Translatable {
        if (!object.remindToName) {
            return '';
        }

        return RemindToTypesDisplayOptions[object.remindToName] ?? object.remindToName;
    }
}
