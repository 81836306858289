import { Injectable } from '@angular/core';
import { Paginator } from '@app/classes';
import { TaskTemplate } from '@app/models/tasks/task-template.model';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import { AssignedToColumn, DueColumn, TaskColumn } from '@employees/services/tables/task-template-table/columns';

@Injectable()
export class OffboardingTaskTemplateTable extends Table {
    static editOption: MenuColumnItem = {
        label: 'Edit',
        event: 'edit',
    };

    static deleteOption: MenuColumnItem = {
        label: 'Delete',
        event: 'delete',
    };

    links = true;
    showMenuColumnOptions = true;
    taskType = 'offboarding';

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    getMenuColumnOptionsForRow(): MenuColumnItem[] {
        return [OffboardingTaskTemplateTable.editOption, OffboardingTaskTemplateTable.deleteOption];
    }

    setColumns(): void {
        this.columns = [new TaskColumn(), new AssignedToColumn(this.taskType), new DueColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            TaskTemplate.param('company', this.auth.company.id).where('task_type', 'offboarding')
        );
    }
}
