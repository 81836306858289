import { Injectable } from '@angular/core';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService } from '@app/services';
import {
    AssetNameColumn,
    CategoryColumn,
    SerialNumberColumn,
    AssignedDateColumn,
} from '@employees/services/tables/employee-assets/columns';
import { Employee } from '@app/models/employee/employee.model';
import { Asset } from '@app/models/company/asset.model';

@Injectable()
export class EmployeeAssetsTable extends Table {
    links = false;
    showMenuColumnOptions = true;

    constructor(
        protected auth: AuthService,
        protected employee: Employee
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        this.columns = [
            new AssetNameColumn(),
            new CategoryColumn(),
            new SerialNumberColumn(),
            new AssignedDateColumn(),
        ];
    }

    setDataSource(): void {
        this.paginator = new Paginator(Asset.where('assignedTo', this.employee.id).with(['assignment', 'category']));
    }
}
