import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Paginator } from '@app/classes';
import { TrainingProgramRecord } from '@app/models/training/training-program-record.model';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import {
    DueDateColumn,
    RepeatsColumn,
    TrainingProgramColumn,
} from '@app/modules/training/services/tables/upcoming-records-index/columns';
import { AuthService } from '@app/services';
import { TrainingProgram } from '@models/training/training-program.model';

const completeAction = {
    label: 'training.complete',
    event: 'complete',
};

@Injectable()
export class UpcomingRecordsIndexTable extends Table {
    links = true;
    showMenuColumnOptions = true;

    constructor(
        protected auth: AuthService,
        public route: ActivatedRoute
    ) {
        super(auth);
        this.boot();
    }

    getMenuColumnOptionsForRow(record: TrainingProgramRecord): MenuColumnItem[] {
        if (!record.isComplete && this.canEdit(record)) {
            return [completeAction];
        }
        return [];
    }

    setColumns(): void {
        this.columns = [new TrainingProgramColumn(), new DueDateColumn(), new RepeatsColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            TrainingProgramRecord.param('company', this.auth.company.id)
                .with(['employee', 'trainingProgram'])
                .where('status', ['pending', 'overdue'])
                .where('employeeId', this.auth.employee.id)
        );
    }

    getLink(record: TrainingProgramRecord): (string | number)[] | null {
        return record.getLinkPath();
    }

    canEdit(record: TrainingProgramRecord): boolean {
        return (
            this.auth.can(TrainingProgram.managePermission) ||
            (record.isEmployeeSubmittable && this.auth.isSelf(record.employee)) ||
            this.auth.supervises(record.employee)
        );
    }
}
