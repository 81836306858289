import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Paginator } from '@app/classes';
import { TrainingProgramRecord } from '@app/models/training/training-program-record.model';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import {
    DueDateColumn,
    EmployeeDepartmentColumn,
    EmployeeNameColumn,
    StatusColumn,
    TrainingProgramColumn,
} from '@app/modules/training/services/tables/training-program-records/columns';
import { AuthService } from '@app/services';
import { Employee } from '@models/employee/employee.model';

const completeRecordOption: MenuColumnItem = {
    label: 'training.record',
    event: 'record',
};

const editOption: MenuColumnItem = {
    label: 'edit',
    event: 'edit',
};

const remindOption: MenuColumnItem = {
    label: 'remind',
    event: 'remind',
};

const deleteOption: MenuColumnItem = {
    label: 'delete',
    event: 'delete',
};

@Injectable()
export class TrainingProgramRecordsTable extends Table {
    links = true;
    showMenuColumnOptions = true;
    excludedEmployee: Employee = null;

    constructor(
        protected auth: AuthService,
        protected route: ActivatedRoute
    ) {
        super(auth);
    }

    init(): void {
        this.boot();
    }

    getMenuColumnOptionsForRow(row: TrainingProgramRecord): MenuColumnItem[] {
        if (row.status === 'complete') {
            return [editOption, deleteOption];
        }
        return [completeRecordOption, remindOption, deleteOption];
    }

    setColumns(): void {
        this.columns = [
            new EmployeeNameColumn(),
            new EmployeeDepartmentColumn(),
            new TrainingProgramColumn(this.auth),
            new DueDateColumn(),
            new StatusColumn(),
        ];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            TrainingProgramRecord.param('company', this.auth.company.id)
                .where('excludeEmployeeId', this.excludedEmployee?.id)
                .with(['employee', 'trainingProgram', 'employee.department'])
        );
    }

    getLink(record: TrainingProgramRecord): (string | number)[] | null {
        return record.getLinkPath();
    }
}
