import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class ReceivedByColumn extends Column {
    title = 'Received by';
    property = 'subject';
    sortField = 'subject.firstName';
    sortable = true;
    type: CellDisplay = CellDisplay.avatarOnly;
}
