import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class EmployeeNameColumn extends Column {
    title = 'training.employeeName';
    property = 'employee';
    sortField = 'employee.lastName';
    sortable = true;
    type: CellDisplay = CellDisplay.avatar;
}
