import { TaskTemplate } from '@app/models/tasks/task-template.model';
import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { TitlecasePipe } from '@app/pipes';

export class AssignedToColumn extends Column {
    titleCasePipe = new TitlecasePipe();
    title = 'Assigned To';
    property = 'assignedTo';
    sortField = 'assignedTo';
    sortable = true;
    type: CellDisplay = CellDisplay.string;
    subjectLabel: string;

    constructor(taskType: string) {
        super();
        this.subjectLabel = taskType === 'onboarding' ? 'New Hire' : 'Offboarding Employee';

        this.filter = new CheckListFilter('assignedTo', [
            { label: this.subjectLabel, value: 'subject' },
            { label: 'Manager', value: 'manager' },
            { label: 'Specific Employee', value: 'assigned' },
            { label: 'Unassigned', value: 'unassigned' },
        ]);
    }

    getDisplayPropertyValue(object: TaskTemplate): string | null {
        let assignedTo = 'unassigned';
        if (object.assigneeType) {
            assignedTo = object.assigneeType === 'subject' ? this.subjectLabel : object.assigneeType;
        }

        if (object.assignee) {
            assignedTo = object.assignee.fullName;
        }

        return this.titleCasePipe.transform(assignedTo);
    }
}
