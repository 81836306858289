import { Injectable } from '@angular/core';
import { File as FileModel } from '@models/common/file.model';
import { FileHelperService } from '@app/services';

export interface HasFileModel {
    id: number;
    file: FileModel;
}

export interface RelationsUpdate {
    attach: number[];
    detach: number[];
}

@Injectable()
export class FormFileManagerService {
    fileModels: FileModel[] = [];
    inputFiles: File[] = [];
    protected detachableFiles: FileModel[] = [];

    constructor(protected fileHelper: FileHelperService) {}

    setHasFileModels(collection: HasFileModel[]): void {
        this.inputFiles = [];
        this.detachableFiles = [];
        this.fileModels = collection.map((file: HasFileModel) => file.file);
    }

    setFileModels(collection: FileModel[]): void {
        this.inputFiles = [];
        this.detachableFiles = [];
        this.fileModels = collection;
    }

    addInputFiles(files: FileList): void {
        this.inputFiles = this.inputFiles.concat(Array.from(files));
    }

    removeInputFile(file: File): void {
        const index = this.inputFiles.indexOf(file);
        if (index !== -1) {
            this.inputFiles.splice(index, 1);
        }
    }

    removeFileModel(file: FileModel): void {
        const index = this.fileModels.indexOf(file);
        if (index !== -1) {
            this.fileModels.splice(index, 1);
        }
        this.detachableFiles.push(file);
    }

    getRelations(): Promise<RelationsUpdate> {
        return this.getFileModels().then((files) => {
            return {
                attach: files.map((file: FileModel) => file.id),
                detach: this.detachableFiles.map((file: FileModel) => file.id),
            };
        });
    }

    async getFileModels(): Promise<FileModel[]> {
        const promises = this.inputFiles.map((file) => {
            return this.fileHelper.store(file);
        });
        return Promise.all(promises);
    }
}
