import { TrainingProgramRecordsTable } from '@app/modules/training/services/tables/training-program-records/training-program-records.table';
import { TrainingProgramsTable } from '@app/modules/training/services/tables/training-programs/training-programs.table';
import { FormFileManagerService } from '@training/services/form-file-manager.service';
import { CompletedRecordsIndexTable } from '@training/services/tables/completed-records-index/completed-records-index.table';
import { EnrolledEmployeesTable } from '@training/services/tables/enrolled-employees/enrolled-employees.table';
import { TrainingProgramsService } from '@training/services/training-programs.service';
import { UpcomingRecordsIndexTable } from './tables/upcoming-records-index/upcoming-records-index.table';
import { TrainingModuleMenuService } from './training-module-menu.service';

const Services = [
    FormFileManagerService,
    TrainingProgramsTable,
    TrainingProgramRecordsTable,
    UpcomingRecordsIndexTable,
    CompletedRecordsIndexTable,
    EnrolledEmployeesTable,
    TrainingProgramsService,
    TrainingModuleMenuService,
];

export {
    CompletedRecordsIndexTable,
    EnrolledEmployeesTable,
    FormFileManagerService,
    Services,
    TrainingModuleMenuService,
    TrainingProgramRecordsTable,
    TrainingProgramsService,
    TrainingProgramsTable,
    UpcomingRecordsIndexTable,
};
