import { Model } from '../core/base.model';

export class HireQuestion extends Model {
    protected static _resource = 'companyManagement/companies/:company/hireQuestions';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['question'];

    protected static _type = 'hireQuestions';

    get question(): string | null {
        return this._attributes['question'] || null;
    }

    set question(value: string) {
        this._attributes['question'] = value;
    }
}
