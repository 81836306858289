import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Column } from '@app/modules/table/classes/column.class';

export class NameColumn extends Column {
    title = 'training.name';
    property = null;
    sortField = 'lastName';
    sortable = true;
    type: CellDisplay = CellDisplay.avatar;
}
