import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Task } from '@models/tasks/task.model';
import { DateRangeFilter } from '@table/classes/column-filters/date-range/date-range-filter.class';

export class DueColumn extends Column {
    title = 'table.all-company-tasks.dueDate';
    property = 'dueAt';
    type: CellDisplay = CellDisplay.date;
    sortable = true;

    constructor() {
        super();

        this.filter = new DateRangeFilter('dueAt');
    }

    getDisplayPropertyValue(task: Task): string | Date {
        return task.dueAt || '-';
    }
}
