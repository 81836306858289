import { Injectable } from '@angular/core';
import { MenuItem } from '@app/interfaces';
import { MenuService } from '@app/services/menu/menu.service';
import { TrainingProgram } from '@models/training/training-program.model';

const TrainingModuleMenu: MenuItem[] = [
    {
        key: 'training.trainingLog',
        link: ['/training', 'activity'],
        can: [TrainingProgram.managePermission],
    },
    {
        key: 'training.trainingPrograms',
        link: ['/training', 'programs'],
        can: [TrainingProgram.managePermission],
    },
    {
        key: 'training.upcomingTraining',
        link: ['/training', 'upcoming'],
        cannot: [TrainingProgram.managePermission],
    },
    {
        key: 'training.completedTraining',
        link: ['/training', 'complete'],
        cannot: [TrainingProgram.managePermission],
    },
    {
        key: 'training.teamLog',
        link: ['/training', 'team-log'],
        can: ['manageDirectReports', 'manageDirectAndIndirectReports'],
        cannot: [TrainingProgram.managePermission],
    },
];

@Injectable()
export class TrainingModuleMenuService extends MenuService {
    setBaseMenu(): void {
        this.baseMenu = TrainingModuleMenu;
    }
}
