import { AuthService } from '@app/services';
import { OnboardingReminder } from '@app/models/employee/onboarding-reminder.model';
import { ReminderColumn, SendToColumn, DelayDaysColumn } from '@employees/services/tables/onboarding-reminder/columns';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { Injectable } from '@angular/core';

const deleteOption: MenuColumnItem = {
    label: 'delete',
    event: 'delete',
};

const editOption: MenuColumnItem = {
    label: 'edit',
    event: 'edit',
};

@Injectable()
export class OnboardingReminderTable extends Table {
    showMenuColumnOptions = true;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    getMenuColumnOptionsForRow(_): MenuColumnItem[] {
        return [editOption, deleteOption];
    }

    setColumns(): void {
        this.columns = [new ReminderColumn(), new SendToColumn(), new DelayDaysColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(OnboardingReminder.param('company', this.auth.company.id));
    }
}
