import { Injectable } from '@angular/core';
import { Salary } from '@app/models/employee/salary.model';
import moment from 'moment';
import { Employee } from '@app/models/employee/employee.model';
import { isBefore, isSameDay } from 'date-fns';
import { CompensationMode } from '@app/modules/forms/employees/components/salary/compensation-mode.enum';

@Injectable()
export class CompensationService {
    assignCompensationEndsAtDates(date: Date, salaries: Salary[], endsAt?: Date): Salary[] {
        const newEndsAtDateForPastCompensations = moment(date).subtract(1, 'days').toDate();

        if (endsAt) {
            endsAt = moment(endsAt).toDate();
        }

        salaries.forEach((salary: Salary) => {
            const effectiveAt = moment(salary.effectiveAt).toDate();
            if (endsAt && effectiveAt > endsAt) {
                return;
            }
            salary.endsAt = newEndsAtDateForPastCompensations;
        });

        return salaries;
    }

    enablePayrollForm(employee: Employee, salary: Salary, pendingPayrollEndDate: Date): boolean {
        if (!(employee.isPayrollSyncEnabled && pendingPayrollEndDate && salary.effectiveAt)) {
            return false;
        }
        const effectiveBeforePayrollEnds = isBefore(salary.effectiveAt, pendingPayrollEndDate);
        const effectiveOnSameDayPayrollEnds = isSameDay(salary.effectiveAt, pendingPayrollEndDate);
        return effectiveBeforePayrollEnds || effectiveOnSameDayPayrollEnds;
    }

    shouldCompensationsBeModified(compensationMode: string, salaries: Salary[]): boolean {
        if (compensationMode === CompensationMode.new && salaries.length) {
            return true;
        }
        return false;
    }
}
