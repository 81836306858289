import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Employee } from '@app/models/employee/employee.model';
import { Salary } from '@app/models/employee/salary.model';
import { Account } from '@app/models/account/account.model';
import { CompanyDocument } from '@app/models/documents/company-document.model';

@Injectable()
export class EmployeeHiringService {
    account: Account;
    employee: Employee;
    salary: Salary;

    constructor(private http: HttpClient) {}

    /**
     * Syncs employee name from account
     * Required because the employee name is used in document selection
     */
    syncEmployeeName(): void {
        this.employee.firstName = this.account.legalFirstName;
        this.employee.lastName = this.account.legalLastName;
    }

    getClaimUrl(document: CompanyDocument): Promise<string> {
        if (!document.hasSigningTemplateConfigured()) {
            console.warn('Document not configured for signing');
            return;
        }

        const url = `/v2/signingTemplates/${document.signingTemplate.id}/employeeClaimUrl`;
        const payload = {
            data: {
                type: 'signingTemplates',
                attributes: {
                    // The order of these is important. We need the account after employee
                    // otherwise the name is not passed in (since we are using legal name)
                    ...this.employee.getAttributes(),
                    ...this.account.getAttributes(),
                    ...this.salary.getAttributes(),
                },
            },
        };

        return this.http
            .post(url, payload)
            .toPromise()
            .then((res: { data: { attributes: { claimUrl: string } } }) => {
                return res.data.attributes.claimUrl;
            });
    }
}
