import { Injectable } from '@angular/core';
import { Platform } from '@app/classes/platform.class';
import { FeatureFlag } from '@app/enums';
import { MenuItem } from '@app/interfaces';
import { TaskTemplate } from '@app/models/tasks/task-template.model';
import { MenuService } from '@app/services/menu/menu.service';
import { HireEmployee } from '@settings/meta/module-feature-permissions.meta';

const EmployeeModuleMenu: MenuItem[] = [
    {
        key: 'employees.menu.directory',
        link: ['/employees', 'directory'],
        can: [...Platform.people.directory.permission.view],
    },
    {
        key: 'employees.menu.myTeam',
        link: ['/employees', 'team'],
        ableTo: 'myTeam',
        can: [...Platform.people.myTeam.permission.view],
    },
    {
        key: 'employees.menu.departments',
        link: ['/employees', 'departments'],
        can: [...Platform.people.departments.permission.view],
    },
    {
        key: 'employees.menu.orgChart',
        link: ['/employees/organization'],
        can: ['view.organizationalChart'],
    },
    {
        key: 'employees.menu.offers',
        link: ['/employees/offer-letters'],
        can: HireEmployee.permissions,
        checkAllPermissions: true,
    },
    {
        key: 'employees.menu.tasks',
        link: ['/employees/tasks'],
        can: [TaskTemplate.managePermission],
        children: [
            {
                key: 'employees.menu.allTasks',
                link: ['/employees/tasks'],
            },
            {
                key: 'employees.menu.taskTemplates',
                link: ['/employees/task-templates'],
            },
        ],
    },
    {
        /**
         * If role does not have TaskTemplate permissions, do not show drop down with a single item.
         */
        key: 'employees.menu.tasks',
        link: ['/employees/tasks'],
        cannot: [TaskTemplate.managePermission],
    },
    {
        key: 'employees.menu.approvals',
        link: [],
        can: Platform.permission.administrate,
        showIfHasFeatureFlag: FeatureFlag.changeRequests,
        children: [
            {
                key: 'employees.menu.requestTemplate',
                link: ['/change-requests/templates'],
            },
            {
                key: 'employees.menu.requestLog',
                link: ['/change-requests/requests'],
            },
        ],
    },
    {
        key: 'employees.menu.approvals',
        link: ['/change-requests/requests'],
        cannot: Platform.permission.administrate,
        showIfHasFeatureFlag: FeatureFlag.changeRequests,
    },
    {
        key: 'employees.menu.settings',
        link: ['/employees/settings'],
        can: ['administrate'],
        children: [
            {
                key: 'employees.menu.onboarding',
                link: ['/employees/settings/onboarding'],
            },
            {
                key: 'employees.menu.offboarding',
                link: ['/employees/settings/offboarding'],
            },
        ],
    },
];

@Injectable()
export class EmployeeModuleMenuService extends MenuService {
    setBaseMenu(): void {
        this.baseMenu = EmployeeModuleMenu;
    }
}
