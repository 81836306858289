import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Column } from '@app/modules/table/classes/column.class';
import { TrainingProgramRecord } from '@models/training/training-program-record.model';
import moment from 'moment';

export class ExpirationColumn extends Column {
    title = 'training.expirationDate';
    property = 'expirationDate';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(model: TrainingProgramRecord): string {
        return model.expirationDate ? moment(model.expirationDate, 'Y-M-D').format('MMM D, Y') : '---';
    }
}
