import { Injectable } from '@angular/core';
import { Platform } from '@app/classes/platform.class';
import { FeatureFlag } from '@app/enums';
import { MenuItem, MenuItemGroup } from '@app/interfaces';
import { Employee } from '@app/models/employee/employee.model';
import { Task } from '@app/models/tasks/task.model';
import { ProfileTabs } from '@app/modules/settings/enums/profile-tabs.enum';
import { MenuService } from '@app/services/menu/menu.service';
import { Account } from '@models/account/account.model';
import { BenefitPlan } from '@models/benefits/benefit-plan.model';
import { Asset } from '@models/company/asset.model';
import { Journey } from '@models/employee/journey.model';
import { Note } from '@models/employee/note.model';
import { Survey } from '@models/employee/performance/survey.model';
import { Reminder } from '@models/employee/reminder.model';
import { ProfilePermissions } from '@settings/meta/profile-permissions.meta';

/**
 * Returns a list of view permissions used on a given tab
 */
function getViewPermissionsFromProfileTab(tab: ProfileTabs): string[] {
    return [
        ...(ProfilePermissions.find((profileTab) => profileTab.tab === tab)?.segments ?? []).reduce<string[]>(
            (acc, val) => {
                acc = acc.concat(val.view);
                return acc;
            },
            []
        ),
    ];
}

/**
 * Definition of all menu items displayed on an employee's profile page
 */
const EmployeeMenu = [
    {
        key: 'employees.tabs.personal',
        link: ['/employees', 'directory', ':employee', 'personal'],
        can: getViewPermissionsFromProfileTab(ProfileTabs.personal),
        checkAllPermissions: false,
    },
    {
        key: 'employees.tabs.jobPay',
        link: ['/employees', 'directory', ':employee', 'job-and-pay'],
        can: getViewPermissionsFromProfileTab(ProfileTabs.jobAndPay),
        checkAllPermissions: false,
    },
    {
        key: 'employees.tabs.documents',
        link: ['/employees', 'directory', ':employee', 'documents'],
        can: [...Platform.people.employeeDocuments.permission.view],
        hideIfHasFeatureFlag: FeatureFlag.documentsV2,
    },
    {
        key: 'employees.tabs.documents',
        link: ['/employees', 'directory', ':employee', 'documents-v2'],
        can: [...Platform.people.employeeDocuments.permission.view],
        showIfHasFeatureFlag: FeatureFlag.documentsV2,
    },
    {
        key: 'employees.tabs.journey',
        link: ['/employees', 'directory', ':employee', 'journey'],
        can: [...Journey.permission.view],
        checkAllPermissions: true,
    },
    {
        key: 'employees.tabs.timeOff',
        link: ['/employees', 'directory', ':employee', 'time-off'],
        can: [...Platform.timeOff.permission.view],
        checkAllPermissions: true,
        module: Platform.modules.timeOff,
    },
    {
        key: 'employees.tabs.performance',
        link: ['/employees', 'directory', ':employee', 'performance'],
        can: [...Platform.performance.permission.view],
        checkAllPermissions: true,
        module: Platform.modules.performance,
    },

    {
        key: 'employees.tabs.surveys',
        link: ['/employees', 'directory', ':employee', 'surveys'],
        can: [...Survey.permission.view, ...Platform.survey.permission.view],
        checkAllPermissions: true,
        module: Platform.modules.survey,
        showIfHasFeatureFlag: FeatureFlag.performanceModuleSplit,
    },
    {
        key: 'employees.tabs.surveys',
        link: ['/employees', 'directory', ':employee', 'surveys'],
        can: [...Survey.permission.view],
        checkAllPermissions: true,
        module: Platform.modules.performance,
        hideIfHasFeatureFlag: FeatureFlag.performanceModuleSplit,
    },
    {
        key: 'employees.tabs.benefits',
        link: ['/employees', 'directory', ':employee', 'benefits'],
        can: [...Platform.benefits.permission.view, ...BenefitPlan.permission.view],
        checkAllPermissions: true,
        module: Platform.modules.benefits,
    },
    {
        key: 'employees.tabs.reminders',
        link: ['/employees', 'directory', ':employee', 'reminders'],
        can: [...Reminder.permission.view],
        checkAllPermissions: true,
    },
    {
        key: 'employees.tabs.records',
        link: ['/employees', 'directory', ':employee', 'records'],
        can: [...Note.permission.view],
        checkAllPermissions: true,
    },
    {
        key: 'employees.tabs.tasks',
        link: ['/employees', 'directory', ':employee', 'tasks'],
        can: [Task.managePermission],
    },
    {
        key: 'employees.tabs.training',
        link: ['/employees', 'directory', ':employee', 'training'],
        checkAccessToEmployee: true,
    },
    {
        key: 'employees.tabs.timeTracking',
        link: ['/employees', 'directory', ':employee', 'time-tracking'],
        module: Platform.modules.timeTracking,
    },
    {
        key: 'employees.tabs.paystubs',
        link: ['/employees', 'directory', ':employee', 'paystubs'],
        can: [...Platform.payroll.permission.view],
        checkAllPermissions: true,
        module: Platform.modules.payroll,
    },
    {
        key: 'employees.tabs.account',
        link: ['/employees', 'directory', ':employee', 'account'],
        can: [...Account.permission.some('view')],
        checkAllPermissions: true,
    },
    {
        key: 'employees.tabs.assets',
        link: ['/employees', 'directory', ':employee', 'assets'],
        can: [Asset.managePermission],
    },
] as const satisfies MenuItem[];

@Injectable()
export class EmployeeMenuService extends MenuService {
    private _employee?: Employee;

    /** areDependenciesLoaded is used in the inherited class to signify the tab bar is done loading.
     * Since this service relies on an employee in context as well, we extend the definition to include it.
     */
    get areDependenciesLoaded(): boolean {
        return Boolean(super.areDependenciesLoaded && this.employee);
    }

    /** Changing the employee in context triggers a refresh of the menu items for that employee  */
    public set employee(employee: Employee) {
        this._employee = employee;
        this.updateMenu();
    }
    public get employee(): Employee | undefined {
        return this._employee;
    }

    setBaseMenu(): void {
        this.baseMenu = EmployeeMenu;
    }

    protected filterMenu(menu: MenuItemGroup): MenuItemGroup {
        if (this.employee && this.auth.canView(this.employee, 'id')) {
            const filteredMenu = super.filterMenu(menu);
            return this.hasTimeTracking
                ? filteredMenu
                : filteredMenu.filter((item) => item.key !== 'employees.tabs.timeTracking');
        }
        // If the user doesn't have view access of the employee they will only see the "personal" tab
        return menu.filter((item) => item.key === 'employees.tabs.personal');
    }

    /**
     * Determines whether to display the time-tracking menu item
     */
    get hasTimeTracking(): boolean {
        if (!this.employee) {
            return false;
        }

        if (!this.employee?.hasModule(Platform.modules.timeTracking)) {
            return false;
        }

        // If the current logged in user doesn't time tracking OR If the current employee we are looking at doesn't time tracking
        if (!this.auth.employee?.hasModule(Platform.modules.timeTracking)) {
            return false;
        }

        // If the current user has the time tracking admin permission or manages the employee i.e Manager or custom role and has the direct report access level
        return this.auth.can(Platform.timeTracking.permission.view) || this.auth.supervises(this.employee);
    }
}
