import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorParser } from '@app/classes';
import { AuthService, NotifyService, PayrollSettingsService } from '@app/services';
import { Settings } from '@app/services/payroll-settings.service';
import { Employee } from '@models/employee/employee.model';
import { Reminder } from '@models/employee/reminder.model';
import { PayrollResources } from '@payroll/payroll.resources';
import moment from 'moment';

export interface RoeContact {
    firstName: null | string;
    lastName: null | string;
    areaCode: null | string | undefined;
    phoneNumber: null | string;
    phoneExtensionNumber: null | string | undefined;
}

@Injectable()
export class EmployeeRoeService {
    constructor(
        private http: HttpClient,
        public auth: AuthService,
        public router: Router,
        private notify: NotifyService,
        public payrollSettingService: PayrollSettingsService
    ) {}

    /**
     * Call the backend to issue an ROE for this employee
     *
     * Returns a promise that resolves to a boolean indicating
     * whether the call succeeded or not.
     *
     * @todo use an employee ROE model with methods (initialize, new, post);
     */
    async issueRoe(employee: Employee, options?: { showErrors?: boolean; redirect?: boolean }): Promise<boolean> {
        const { showErrors, redirect } = Object.assign({ showErrors: true, redirect: true }, options);

        $('.app-loader').show().addClass('active');

        const payload = await this.buildNewROEPayload();

        return this.http
            .post(PayrollResources.StoreRoe.replace(':employee', employee.id.toString()), payload)
            .toPromise()
            .then((res) => {
                if (redirect) {
                    this.router.navigate(['/payroll', 'records', 'roes', res['data']['id'], 'edit']);
                }

                return true;
            })
            .catch((err) => {
                $('.app-loader').hide().removeClass('active');

                if (showErrors) {
                    this.notify.error(this.parseJsonApiErrorResponse(err) || 'Could not save ROE');
                }

                return false;
            });
    }

    /**
     * Generate a reminder for the admin to issue an ROE for the employee
     */
    async generateReminder(employee: Employee, date: Parameters<typeof moment>[0], notify = true): Promise<Reminder> {
        if (!this.auth.employee || !this.auth.company) {
            throw new Error("Couldn't generate reminder, are you logged in?");
        }

        const reminder = new Reminder({
            employeeId: this.auth.employee.id,
            creatorId: this.auth.employee.id,
            subject: `Reminder to generate an ROE for ${employee.fullName}`,
            body: `Generate the record of employment for ${employee.fullName}. To generate the ROE, visit the employee's profile.`,
            remindAt: moment(date).toDate(),
            repeatOn: 'weekly',
        });

        await reminder.param('company', this.auth.company.id).param('employee', this.auth.employee.id).save();

        if (notify) {
            this.notify.success('Reminder saved');
        }

        return reminder;
    }

    /**
     * @todo standardize error responses from backend
     */
    private parseJsonApiErrorResponse(err: HttpErrorResponse): string {
        return ErrorParser.parse(err.error);
    }

    private async determineROEContact(): Promise<RoeContact> {
        await this.payrollSettingService.load();
        const companyPayrollSettings: Settings = this.payrollSettingService.settings$.value;

        if (
            companyPayrollSettings.roeContactFirstName != null &&
            companyPayrollSettings.roeContactLastName != null &&
            companyPayrollSettings.roeContactPhoneNumber != null
        ) {
            return {
                firstName: companyPayrollSettings.roeContactFirstName,
                lastName: companyPayrollSettings.roeContactLastName,
                areaCode: companyPayrollSettings.roeContactAreaCode,
                phoneNumber: companyPayrollSettings.roeContactPhoneNumber,
                phoneExtensionNumber: companyPayrollSettings.roeContactPhoneExtensionNumber,
            };
        } else {
            const isEmployee = this.auth.employee;
            return isEmployee
                ? {
                      firstName: this.auth.employee.firstName,
                      lastName: this.auth.employee.lastName,
                      areaCode: null,
                      phoneNumber: this.auth.employee.phoneWork,
                      phoneExtensionNumber: null,
                  }
                : {
                      firstName: this.auth.company.name,
                      lastName: this.auth.company.name,
                      areaCode: null,
                      phoneNumber: this.auth.company.phonePrimary,
                      phoneExtensionNumber: null,
                  };
        }
    }

    private async buildNewROEPayload(): Promise<object> {
        const contact = await this.determineROEContact();

        return {
            data: {
                attributes: {
                    reasonForIssuing: {
                        contact,
                    },
                },
            },
        };
    }
}
