import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { TaskTemplate } from '@models/tasks/task-template.model';
import { LimitToPipe } from '@app/pipes';

export class NameColumn extends Column {
    title = 'table.task-templates.templateName';
    property = 'name';
    sortField = 'name';
    type: CellDisplay = CellDisplay.string;
    sortable = true;
    limitToPipe = new LimitToPipe();

    getDisplayPropertyValue(taskTemplate: TaskTemplate): string {
        return this.limitToPipe.transform(taskTemplate.name, 50);
    }
}
