import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { OnboardingReminder } from '@models/employee/onboarding-reminder.model';

export class DelayDaysColumn extends Column {
    title = 'employees.onboardingSettings.sendAt';
    property = 'delayDays';
    sortable = true;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(object: OnboardingReminder): string | number | null {
        if (object.remindBefore && object.delayDays) {
            return `${object.delayDays} days before hire`;
        }

        if (!object.remindBefore && object.delayDays) {
            return `${object.delayDays} days after hire`;
        }

        return 'On day of hire';
    }
}
