import { AuthService } from '@app/services';
import { OffboardingReminder } from '@models/employee/offboarding-reminder.model';
import { ReminderColumn, SendToColumn, DelayDaysColumn } from '@employees/services/tables/offboarding-reminder/columns';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { Injectable } from '@angular/core';

const deleteOption: MenuColumnItem = {
    label: 'Delete',
    event: 'delete',
};

const editOption: MenuColumnItem = {
    label: 'Edit',
    event: 'edit',
};

@Injectable()
export class OffboardingReminderTable extends Table {
    showMenuColumnOptions = true;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    getMenuColumnOptionsForRow(_): MenuColumnItem[] {
        return [editOption, deleteOption];
    }

    setColumns(): void {
        this.columns = [new ReminderColumn(), new SendToColumn(), new DelayDaysColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(OffboardingReminder.param('company', this.auth.company.id).limit(5), {
            pageSize: 5,
        });
    }
}
