import { Injectable } from '@angular/core';
import { AuthService } from '@app/services';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { TitleColumn, DateCreatedColumn, LastModifiedColumn } from './columns';
import { Note } from '@models/employee/note.model';
import { Employee } from '@app/models/employee/employee.model';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';

const editOption: MenuColumnItem = {
    label: 'edit',
    event: 'edit',
};

const deleteOption: MenuColumnItem = {
    label: 'delete',
    event: 'delete',
};

@Injectable()
export class EmploymentRecordsTable extends Table {
    links = false;
    showMenuColumnOptions = true;

    constructor(
        protected auth: AuthService,
        private employee: Employee
    ) {
        super(auth);
        this.boot();
    }

    getMenuColumnOptionsForRow(): MenuColumnItem[] {
        return [editOption, deleteOption];
    }

    setColumns(): void {
        this.columns = [new TitleColumn(), new DateCreatedColumn(), new LastModifiedColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(Note.param('company', this.auth.company.id).param('employee', this.employee.id));
    }
}
