import { AuthService } from '@app/services';
import { TrainingProgramRecord } from '@app/models/training/training-program-record.model';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateColumn, TrainingProgramColumn } from '@employees/services/tables/training/columns';
import { Employee } from '@models/employee/employee.model';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';

const completeRecordOption: MenuColumnItem = {
    label: 'Record',
    event: 'record',
};

const remindOption: MenuColumnItem = {
    label: 'Remind',
    event: 'remind',
};

const deleteOption: MenuColumnItem = {
    label: 'Delete',
    event: 'delete',
};

@Injectable()
export class UpcomingTrainingIndexTable extends Table {
    links = true;
    showMenuColumnOptions = true;

    constructor(
        protected auth: AuthService,
        public route: ActivatedRoute,
        protected employee: Employee
    ) {
        super(auth);
        this.boot();
    }

    getMenuColumnOptionsForRow(): MenuColumnItem[] {
        return [completeRecordOption, remindOption, deleteOption];
    }

    setColumns(): void {
        this.columns = [new TrainingProgramColumn(this.employee, this.auth), new DateColumn('Due Date', 'dueAt')];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            TrainingProgramRecord.param('company', this.auth.company.id)
                .limit(5)
                .where('employee_id', this.employee.id)
                .where('status', ['pending', 'overdue'])
                .with(['employee', 'trainingProgram']),
            { pageSize: 5 }
        );
    }

    getLink(record: TrainingProgramRecord): (string | number)[] | null {
        return record.getLinkPath();
    }
}
