import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Size } from '@app/components/avatar/avatar.component';

export class RequiredForColumn extends Column {
    title = 'table.all-company-tasks.createdFor';
    property = 'subject';
    sortField = 'subject.lastName';
    sortable = true;
    type: CellDisplay = CellDisplay.avatar;
    size: Size = 'medium';
}
