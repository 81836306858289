import { TrainingProgram } from '@app/models/training/training-program.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Translatable } from '@app/types/translatable.type';

export class RepeatsColumn extends Column {
    title = 'training.repeats';
    sortable = false;
    type: CellDisplay = CellDisplay.string;

    getDisplayPropertyValue(trainingProgram: TrainingProgram): Translatable {
        return trainingProgram.recurrenceLabel;
    }
}
