import { AuthService } from '@app/services';
import { TrainingProgram } from '@app/models/training/training-program.model';
import {
    DueDateColumn,
    NameColumn,
    RepeatsColumn,
} from '@app/modules/training/services/tables/training-programs/columns';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { Injectable } from '@angular/core';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';

const editOption: MenuColumnItem = {
    label: 'edit',
    event: 'edit',
};

const deleteOption: MenuColumnItem = {
    label: 'delete',
    event: 'delete',
};

@Injectable()
export class TrainingProgramsTable extends Table {
    links = true;
    showMenuColumnOptions = true;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    getMenuColumnOptionsForRow(): MenuColumnItem[] {
        return [editOption, deleteOption];
    }

    setColumns(): void {
        this.columns = [new NameColumn(), new RepeatsColumn(), new DueDateColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(TrainingProgram.param('company', this.auth.company.id));
    }
}
