import { Translatable } from '@app/types/translatable.type';

const RemindToTypesDisplayOptions: { [key: string]: Translatable } = {
    manager: 'employees.settingsOffboarding.manager',
    employee: 'employees.settingsOffboarding.employee',
    administrator: 'employees.settingsOffboarding.administrator',
    colleague: 'employees.settingsOffboarding.colleague',
};

export { RemindToTypesDisplayOptions };
