import { Task } from '@app/models/tasks/task.model';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { LimitToPipe } from '@app/pipes';

export class NameColumn extends Column {
    title = 'table.all-company-tasks.name';
    property = 'name';
    sortField = 'name';
    type: CellDisplay = CellDisplay.string;
    sortable = true;
    limitToPipe = new LimitToPipe();

    getDisplayPropertyValue(task: Task): string {
        return this.limitToPipe.transform(task.name, 50);
    }
}
