import { Project } from '@app/models/time-tracking/project.model';
import { TimesheetEntry } from '@app/models/time-tracking/timesheet-entry.model';
import { CheckListFilter } from '@app/modules/table/classes/column-filters/check-list/check-list-filter.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { FilterValue } from '@app/modules/table/types/filter-value.type';

export class ProjectColumn extends Column {
    title = 'time-tracking.timesheetsIndexPage.table.project';
    property = 'project';
    type: CellDisplay = CellDisplay.string;
    sortable = true;
    sortField = 'projectNameWithNull';

    constructor() {
        super();
        this.setOptions();
    }

    getDisplayPropertyValue(timesheetEntry: TimesheetEntry): string {
        return timesheetEntry.project ? timesheetEntry.project.name : '-';
    }

    private setOptions(): void {
        const options = Project.all().then(([projects]) => {
            const projectsOptions: { label: string; value: FilterValue }[] = projects
                .map((project: Project) => ({
                    label: project.name,
                    value: project.id,
                }))
                .sort((a, b) => a.label.localeCompare(b.label));

            projectsOptions.unshift({ label: '-', value: null });

            return projectsOptions;
        });

        this.filter = new CheckListFilter('projectId', options);
    }
}
