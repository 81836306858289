import { Injectable } from '@angular/core';
import { Paginator } from '@app/classes';
import { TaskTemplate } from '@app/models/tasks/task-template.model';
import { Task } from '@app/models/tasks/task.model';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import {
    AssignedToColumn,
    DueColumn,
    NameColumn,
    RequiredForColumn,
    SubTasksCountColumn,
    TaskStatusColumn,
} from './columns';

const remindOption: MenuColumnItem = {
    label: 'remind',
    event: 'remind',
};

const editOption: MenuColumnItem = {
    label: 'edit',
    event: 'edit',
};

const deleteOption: MenuColumnItem = {
    label: 'delete',
    event: 'delete',
};

@Injectable()
export class TaskTemplateCompanyTasksTable extends Table {
    links = false;
    showMenuColumnOptions = true;

    constructor(
        protected auth: AuthService,
        private taskTemplate: TaskTemplate
    ) {
        super(auth);
        this.boot();
    }

    getMenuColumnOptionsForRow(): MenuColumnItem[] {
        // TODO: update method to show options based on permissions
        return [remindOption, editOption, deleteOption];
    }

    setColumns(): void {
        this.columns = [
            new NameColumn(),
            new AssignedToColumn(),
            new RequiredForColumn(),
            new SubTasksCountColumn(),
            new DueColumn(),
            new TaskStatusColumn(),
        ];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            Task.param('company', this.auth.company.id)
                .where('taskTemplateId', this.taskTemplate.id)
                .with([
                    'subject',
                    'subject.manager',
                    'assignees',
                    'assignees.manager',
                    'subtasks',
                    'file',
                    'employeeDocument',
                    'completedBy',
                    'subtasksCount',
                ])
        );
    }
}
