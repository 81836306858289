import { Injectable } from '@angular/core';
import { AuthService } from '@app/services';
import { ChangeRequestTemplate } from '@models/change-requests/change-request-template.model';
import { Employee } from '@models/employee/employee.model';

@Injectable()
export class EmployeeChangeRequestTemplatesService {
    constructor(private auth: AuthService) {}

    canRequestForEmployee(employee: Employee): boolean {
        return (
            (this.auth.supervises(employee) || this.auth.isAdmin()) &&
            employee.isActive() &&
            employee?.account?.isActive
        );
    }

    async getActiveTemplates(): Promise<ChangeRequestTemplate[]> {
        return ChangeRequestTemplate.where('isActive', 1)
            .get()
            .then(([changeRequestTemplates, _]) => {
                return changeRequestTemplates;
            });
    }
}
