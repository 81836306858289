import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Employee } from '@models/employee/employee.model';
import { TaskTemplate } from '@models/tasks/task-template.model';

@Injectable()
export class AssignTaskTemplatesService {
    constructor(private http: HttpClient) {}

    onSave(selectedTaskTemplates: TaskTemplate[], employees: Employee[]): Promise<any> {
        const url = this.getAssignTaskTemplatesEndpoint();
        const payload = {
            data: {
                type: 'taskTemplates',
                attributes: {
                    employees: employees.map((employee) => employee.id),
                    taskTemplates: selectedTaskTemplates.map((template) => template.id),
                },
            },
        };

        return this.http.post(url, payload).toPromise();
    }

    private getAssignTaskTemplatesEndpoint(): string {
        return environment.api + '/v2/taskTemplates/assign';
    }
}
