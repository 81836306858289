import { Model } from '@models/core/base.model';

export class ChangeRequestTemplateField extends Model {
    protected static _serializeAttributes = ['name', 'key'];

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get key(): string {
        return this._attributes['key'];
    }

    set key(val: string) {
        this._attributes['key'] = val;
    }
}
