import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { TitlecasePipe } from '@app/pipes';
import { Translatable } from '@app/types/translatable.type';
import { TaskTemplateTypesDisplayOptions } from '@app/types/translatables/task-template-types-display.options';
import { TaskTemplate } from '@models/tasks/task-template.model';
import { CheckListFilter } from '@table/classes/column-filters/check-list/check-list-filter.class';

export class TaskTypeColumn extends Column {
    title = 'table.task-templates.type';
    property = 'taskType';
    sortField = 'taskType';
    type: CellDisplay = CellDisplay.string;
    sortable = true;
    private titleCasePipe: TitlecasePipe = new TitlecasePipe();

    constructor() {
        super();

        this.filter = new CheckListFilter('taskType', [
            { label: 'table.task-templates.onboarding', value: 'onboarding' },
            { label: 'table.task-templates.offboarding', value: 'offboarding' },
            { label: 'table.task-templates.general', value: 'general' },
        ]);
    }

    getDisplayPropertyValue(taskTemplate: TaskTemplate): Translatable {
        return (
            TaskTemplateTypesDisplayOptions[taskTemplate.taskType] ??
            this.titleCasePipe.transform(taskTemplate.taskType)
        );
    }
}
