import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { Column } from '@app/modules/table/classes/column.class';

export class SendToColumn extends Column {
    title = 'employees.onboardingSettings.sendTo';
    property = 'remindToName';
    sortField = 'remindTo';
    sortable = true;
    type: CellDisplay = CellDisplay.string;
}
