import { Injectable } from '@angular/core';
import { AuthService } from '@app/services';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { Employee } from '@app/models/employee/employee.model';
import { TitleColumn, RevieweeColumn, DueDateColumn, StatusColumn } from './columns';
import { Review } from '@app/models/performance/review.model';

@Injectable()
export class EmployeeReviewsTable extends Table {
    links = true;

    constructor(
        protected auth: AuthService,
        private employee: Employee
    ) {
        super(auth);
        this.boot();
    }

    getLink(review: Review): (string | number)[] {
        return ['/performance', 'reviews', review.id];
    }

    setColumns(): void {
        this.columns = [new TitleColumn(), new RevieweeColumn(), new DueDateColumn(), new StatusColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            Review.param('company', this.auth.company.id).where('subjectId', this.employee.id).with('subject').limit(5),
            { pageSize: 5 }
        );
    }
}
