import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';

export class EmployeeDepartmentColumn extends Column {
    title = 'training.employeeDepartment';
    property = 'employee.department.name';
    sortable = true;
    sortField = 'departmentName';
    type: CellDisplay = CellDisplay.string;
}
