import { ButtonTypes } from '@app/components/platform/button/button.component';
import { TimesheetEntry } from '@app/models/time-tracking/timesheet-entry.model';
import { ButtonColumn } from '@app/modules/table/classes/button-column.class';
import { AuthService } from '@app/services';

export class ApproveButtonColumn extends ButtonColumn<TimesheetEntry> {
    title = '';
    buttonText = 'time-tracking.approve';
    buttonType: ButtonTypes = 'link';
    event = 'approve';

    constructor(
        private authService: AuthService,
        private currentEmployee: number
    ) {
        super();
    }

    showIf(timesheetEntry: TimesheetEntry): boolean {
        if (timesheetEntry.isApproved) {
            return false;
        }

        if (timesheetEntry.project?.approvalFlow === 'manager') {
            return this.authService.supervises(this.currentEmployee);
        }

        return this.authService.isAssignedTimeTrackingAdmin();
    }
}
